export type Colors = "gray" | "red" | "blue" | "violet" | "lime" | "amber" | "black" | "pink" | "cyan"

export const ColorsCategory: Record<Colors, string> = {
  black: "text-black dark:text-white",
  gray: "text-primary/80",
  red: "text-red-600 dark:text-red-400",
  blue: "text-blue-600 dark:text-blue-400",
  violet: "text-violet-600 dark:text-violet-400",
  lime: "text-emerald-700 dark:text-emerald-400",
  amber: "text-amber-600 dark:text-amber-400",
  pink: "text-pink-600 dark:text-pink-400",
  cyan: "text-cyan-600 dark:text-cyan-400",
}

export const BgColorsCategory: Record<Colors, string> = {
  black: "bg-black dark:bg-white",
  gray: "bg-gray-600 dark:gray-400",
  red: "bg-red-600 dark:bg-red-400",
  blue: "bg-blue-600 dark:bg-blue-400",
  violet: "bg-violet-600 dark:bg-violet-400",
  lime: "bg-emerald-600 dark:bg-emerald-400",
  amber: "bg-amber-600 dark:bg-amber-400",
  pink: "bg-pink-600 dark:bg-pink-400",
  cyan: "bg-cyan-600 dark:bg-cyan-400",
}